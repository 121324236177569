
import logo from "./logo.png";
import bandphoto from "./bandphoto2023.JPG";
import irminsulphoto from "./irminsul_band.jpg";
import skugganposter from "./skuggan_poster.png";
import ofärdposter from "./ofärd_poster.png";
import johan from "./johan2023.JPG";
import leo from "./leo2023.JPG";
import anton from "./anton2023.JPG";
import './App.css';

function App() {
  return (
    <div id="app" class="canvas">
      <div id="main" class="main">
        <div id="logo" class="App-header">
          <img src={logo} alt="logo-main" class="App-logo"/>
          <div id="links" class="link-container">
          <div class="link-row">
            <a class="links" href="https://lombolo.bandcamp.com/">Bandcamp</a>
            <a class="links" href="https://open.spotify.com/artist/1sXdFsdL30LI9v49butVfS?si=FI_wEZs3S4GVpZQ6pWMMJQ">Spotify</a>
            <a class="links" href="https://www.youtube.com/c/Lombolo">YouTube</a>
          </div>
          <div class="link-row">
            <a class="links" href="https://www.facebook.com/lombolo/">Facebook</a>
            <a class="links" href="https://www.instagram.com/lombolo/">Instagram</a>
          </div>
          <div class="link-row">
            <a class="links" href="https://lombolo.creator-spring.com/">Merch</a>
            <a class="links" href="mailto:leonard.walerius@gmail.com">Contact</a>
          </div>
          </div>
        </div>
        <div id="bio-container" class="bio-container">
          <div class="title-2">Biography</div>
          <img src={bandphoto} alt="bandphoto" class="bandphoto"/>
              <div id="bio-text" class="bio-text">
                <div class="bio-paragraph">
                  Out of the dark Swedish forests comes Lombolo, a folk-metal band that is mixing old traditions with a new sound.  Lombolo blends melodic death metal and power metal with the Nordic folk music of their home while also drawing upon Celtic, Mediterranean, and Middle Eastern traditional music.  Catchy, fierce, and melancholic, Lombolo tells tales of folklore and history in their native Swedish language.  Vocals are provided by all three members with styles ranging from gentle and melodic to death growls and black metal shrieks.  Accompaniment is provided by flute, mandolin, accordion, and kantele.
                </div>
                <div class="bio-paragraph">
                The childhood friends Lennart (clean vocals, flute, accordion) and Walerius (guitars, mandolin, kantele, growls) had played music together since they were 9 years old. When they met Johan (bass, clean and harsh vocals) in 2017 the trio was complete. The band is based in Sollentuna, but together, the members have roots from all over Scandinavia, from Korpilombolo to Bornholm.
                </div>
                <div class="bio-paragraph">
                The debut single Bärsärkagång was released in 2019 and the music video quickly got over 10.000 views on YouTube. After releasing three more singles they released Vandringsmannen, their first full-length album in July of 2020. The band's latest effort is the "Ofärd" EP from 2021. "Ofärd" is Lombolo at its finest with catchy flute and mandolin melodies which will make you want to roam the lands in search of adventure and glory!
                </div>
                <div class="bio-paragraph">
                Currently, Lombolo is preparing an album release for July 2023. The final single, "Kung Agne" will be released on May 24th, featuring vocals from Rietas.
                </div>
              </div>
          </div>
          <div id="video-container"class="video-container">
            <div class="title-2">Music Videos</div>
            <div class="video-title">Music video for our latest song "Ofärd"</div>
            <div class="youtube-container"><iframe width="auto" height="auto" src="https://www.youtube.com/embed/8idklF6Nxbs" class="youtube" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>
            <div class="video-title">Lyric Video for Western-themed "Skuggan Från Prärien"</div>
            <div class="youtube-container"><iframe width="auto" height="auto" src="https://www.youtube.com/embed/gqcTBR5nv5Q" class="youtube" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>
            <div class="video-title">Music Video for our song "Irminsûl"</div>
            <div class="youtube-container"><iframe class="youtube" width="auto" height="auto" src="https://www.youtube.com/embed/a7iHZwX4SoQ" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe></div>
          </div>
          <div class="gallery">
            <div class="title-2">
              Releases
            </div>
            <div class="bio-text">For better viewing, please view this section on your desktop browser.</div>
            <div><iframe title="ofärd" style={{border: 0, width: "400px", height: "307px",}} src="https://bandcamp.com/EmbeddedPlayer/album=576139621/size=large/bgcol=ffffff/linkcol=0687f5/artwork=small/transparent=true/" seamless><a href="https://lombolo.bandcamp.com/album/of-rd-ep">Ofärd EP by Lombolo</a></iframe></div>
            <div class="bio-text">Ofärd EP was released in November 2021. It is the band's most diverse release yet. Songs like the Spaghetti-Western inspired "Skuggan Från Prärien" and the anti-imperialist melo-death of "Irminsûl" showcase the bands versatility. The title track Ofärd is the most technically and structurally complex song to date. The Ofärd EP also features new folk instruments like the mandolin and the kantele (a Finnish box harp).Music videos for four out of the five tracks have been released on the band's YouTube channel.</div>
            <a class="link-button" href="https://lombolo.bandcamp.com/album/of-rd-ep">Buy</a>
            <iframe title="vandringsmannen" style={{border: 0, width: "400px", height: "439px"}} src="https://bandcamp.com/EmbeddedPlayer/album=2833997976/size=large/bgcol=ffffff/linkcol=0687f5/artwork=small/transparent=true/" seamless><a href="https://lombolo.bandcamp.com/album/vandringsmannen">Vandringsmannen by Lombolo</a></iframe>
            <div class="bio-text">Vandringsmannen was released in July 2020 and was the band's first full-length album. Vandringsmannen contains 9 songs of pure, straight forward folk metal, the catchy and fist pumping "Bärsärkagång" and "Först till Kvarn" being the lead singles. The songs' lyrics range from tales about Vikings to vampires to the fall of Roman Britain.</div>
            <a class="link-button" href="https://lombolo.bandcamp.com/album/vandringsmannen">Buy</a>
          </div>
          <div class="gallery">
            <div class="title-2">The Band</div>
            <div class="member-presentation">
              <img src={anton} alt="anton" class="gallery-pic"/>
              <div>Lennart Lanström</div>
              <div class="bio-text">Lennart Lanström is the band's frontman. Lennart plays the flute and sings lead clean vocals. He is also in charge of making the graphics and visuals for the band.</div>
            </div>
            <div class="member-presentation">
              <img src={johan} alt="johan" class="gallery-pic"/>
              <div>Johan Maxén</div>
              <div class="bio-text">Johan Maxén plays the bass guitar and does both clean and harsh vocals. Johan also edits and directs the band's music videos.</div>
            </div>
            <div class="member-presentation">
              <img src={leo} alt="leo" class="gallery-pic"/>
              <div>Leonard Walerius</div>
              <div class="bio-text">Leonard Walerius plays the electric guitar, mandolin and kantele, and also does harsh vocals. Walerius is the band's producer, recording the band in his studio and mixing the songs.</div>
            </div>
          </div>
          <div class="gallery">
            <div class="title-2">Gallery</div>
            <img src={irminsulphoto} alt="irminsulphoto" class="gallery-pic"/>
            <div class="title-3">Band photo for Irminsûl</div>
            <img src={skugganposter} alt="skugganposter" class="gallery-pic"/>
            <div class="title-3">Movie-style poster for the "Skuggan Från Prärien" Lyric Video</div>
            <img src={ofärdposter} alt="ofärdposter" class="gallery-pic"/>
            <div class="title-3">Movie-style poster for the "Ofärd" Music Video</div>
          </div>
      </div>
    </div>
  );
}

export default App;
